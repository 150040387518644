<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Determine which factors could lead to each of the following effects on the reaction rate.
      </p>

      <p class="text-bold mb-n1">a) Rate increase</p>

      <v-checkbox
        v-for="option in options"
        :key="'pt-1-' + option.value"
        v-model="inputs.input1"
        :value="option.value"
        :dense="true"
        hide-details="true"
        :ripple="false"
        class="pl-8 mb-0"
        :label="option.condition"
      />

      <p class="text-bold mt-5 mb-n1">b) Rate decrease</p>

      <v-checkbox
        v-for="option in options"
        :key="'pt-2-' + option.value"
        v-model="inputs.input2"
        :value="option.value"
        :dense="true"
        hide-details="true"
        :ripple="false"
        class="pl-8"
        :label="option.condition"
      />

      <p class="text-bold mt-5 mb-n1">c) No change in rate</p>

      <v-checkbox
        v-for="option in options"
        :key="'pt-3-' + option.value"
        v-model="inputs.input3"
        :value="option.value"
        :dense="true"
        hide-details="true"
        :ripple="false"
        class="pl-8"
        :label="option.condition"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'Question85',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: [],
        input3: [],
      },
      options: [
        {condition: 'Increase in temperature', value: '1'},
        {condition: 'Decrease a reactant concentration', value: '2'},
        {condition: 'Addition of a catalyst', value: '3'},
        {condition: 'Decrease surface area of a solid reactant', value: '4'},
        {condition: 'None of the above', value: '5'},
      ],
    };
  },
};
</script>
